export const AUTH_USER_EMAIL_KEY = 'app.email';
export const AUTH_USER_EMAIL_CONFIRMED_KEY = 'app.email_confirmed';
export const APP_PORTFOLIO_ID = 'app.portfolio.id';
export const APP_PORTFOLIO_LANDING = '/p/m/dashboard';
export const APP_LOGIN_PATH = '/a/login';
export const APP_LOGOUT_PATH = '/a/logout';
export const APP_CONFIRM_EMAIL_PATH = '/a/confirmemail';
export const APP_PROFILE_PATH = '/m/profile';

export type FormRegex = {
    expression: RegExp,
    helpText: string
}

export type InvitedAppUser = {
    uuid: string,
    email: string
}

export const GenericSelectRegex:FormRegex = {
    expression: /.+/,
    helpText: "Must contain at least one character."
};


export const PasswordRegex: FormRegex[] = [
    {
        expression: /[!@#$%^&*(),.?":{}|<>]/,
        helpText: "Password must contain a special character.",
    },
    {
        expression: /.{8,}/,
        helpText: "Password is too short. Minimum length is 8 characters.",
    },
    {
        expression: /\d/,
        helpText: "Password must contain at least one number character.",
    },
    {
        expression: /[A-Z]/,
        helpText: "Password must contain at least one upper case character.",
    },
];

export const PasswordFormRegex: FormRegex = {
    expression: /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)(?=.*[A-Z]).{8,}$/,
    helpText: "Password must meet all specified constraints.",
};

export const GenericInputRegex:FormRegex = {
    expression: /.+/,
    helpText: "Select an option."
}
export const YearRegex:FormRegex = {
    expression: /^[0-9]{4}$/,
    helpText: "Must be four digits in the format yyyy."
}
export const ApplicationIdRegex:FormRegex = {
    expression: /^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/,
    helpText: "Must be in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx."
}
export const UuidRegex:FormRegex = {
    expression: /^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/,
    helpText: "Must be in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx."
}
export const AddressLine1Regex:FormRegex = {
    expression: /^[A-Za-z0-9_.'\-#@%&/\s]+$/,
    helpText: "Must be alphanumeric and can only contain special characters .'-#@%&"
}
export const AddressLine2Regex:FormRegex = {
    expression:/^[A-Za-z0-9.'\-#@%&/\s]+$/,
    helpText: "Must be alphanumeric and can only contain special characters .'-#@%&"
}
export const CityRegex:FormRegex = {
    expression:/^[A-Za-z-.\s]{1,}$/,
    helpText: "Must contain only letters or special characters .-."
}
export const ZipRegex:FormRegex = {
    expression:/^(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)$/,
    helpText: "Must be 5 to 9 digits in the format xxxxx-xxxx."
}
export const FullNameRegex:FormRegex = {
    expression:/^[A-Za-z.\-\s]{2,}$/,
    helpText: "Must be at least 2 characters and contain only letters, ., and -."
}
export const PhoneNumberRegex:FormRegex = {
    expression:/^\b\d{3}[-.]?\d{3}[-.]?\d{4}\b$/,
    helpText: "Must contain 10 digits in the format xxx-xxx-xxxx."
}
export const DollarAmountRegex:FormRegex = {
    expression:/^[0-9]+.?[0-9]{0,2}$/,
    helpText: "Must contain only numbers."
}
export const DayOfMonthRegex:FormRegex = {
    expression:/^[1-9]{1}$|^1[0-9]$|^2[0-8]$$/,
    helpText: "Must be a day of month between 1 and 28."
}
export const GenericNumberRegex:FormRegex = {
    expression:/^[0-9]+$/,
    helpText: "Must contain only numbers."
}
export const DateRegex:FormRegex = {
    expression:/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
    helpText: "Must be a valid date in the format mm/dd/yyyy."
}
export const FirstNameRegex:FormRegex = {
    expression:/^[A-Za-z\s]{2,}$/,
    helpText: "Must be at least two characters and contain only letters."
}
export const MiddleInitialRegex:FormRegex = {
    expression:/^[A-Za-z]+$/,
    helpText: "Must contain only letters."
}
export const LastNameRegex:FormRegex = {
    expression:/^[A-Za-z\s]{2,}$/,
    helpText: "Must be at least two characters and contain only letters."
}
export const EmailRegex:FormRegex = {
    expression:/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
    helpText: "Must be a valid email address."
}
export const SsnRegex:FormRegex = {
    expression:/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/,
    helpText: "Must contain 9 digits in the format xxx-xx-xxxx."
}
export const DriversLicenseRegex:FormRegex = {
    expression: /.{4,}/,
    helpText: "Must contain at least four characters."
}
export const PropertyNameRegex:FormRegex = {
    expression:/^[A-Za-z0-9\s]{2,}$/,
    helpText: "Must be at least 2 characters and contain only letters."
}
export const USBankRoutingNumberRegex:FormRegex = {
    expression:/^[0-9]{9}$/,
    helpText: "Must be 9 digits."
}
export const USBankAccountNumberRegex:FormRegex = {
    expression:/^[0-9a-zA-Z]{4,17}$/,
    helpText: "Must be between 4 and 17 alphanumeric characters."
}
export const USAccountNameRegex:FormRegex = {
    expression:/^[A-Za-z\s]{2,}$/,
    helpText: "Must be at least 2 characters and contain only letters."
}
export const EINRegex:FormRegex = {
    expression:/^[0-9]{2}-?[0-9]{7}$/,
    helpText: "Must be 9 digits in the format xx-xxxxxxx."
}

export enum BootstrapValidateClass {
    isvalid = "is-valid", isinvalid = "is-invalid", unvalidated = ""
}


export type FormElementValidation = {
    id: string,
    validate: ValidateElementFunction,
    regex: FormRegex,
    required: boolean
}

export type ValidateElementFunction = (value: string, required: boolean, validation: RegExp) => boolean;
export type ValidatePasswordFunction = (value: string) => string[];

export const formPasswordValidateMessages: ValidatePasswordFunction = (value: string): string[] => {
    const errors:string[] = [];
        
        PasswordRegex.forEach((rule) => {
            if (!rule.expression.test(value)) {
                errors.push(rule.helpText);
            }
        });

        return errors;
}

export const formInputValidate: ValidateElementFunction = (value: string, required: boolean, validation: RegExp): boolean => {
    if (required) {
        if (value && value.match(validation)) {
            return true;
        }
        return false;
    } else {
        if ((value && value.length === 0) || !value) {
            return true;
        }
        if (value && value.match(validation)) {
            return true;
        }
        return false;
    }
}

export const formSelectValidate: ValidateElementFunction = (value: string, required: boolean, validation: RegExp): boolean => {
    if (value && value.match(validation)) {
        return true;
    }
    return false;
}

export type Unit = {
    uuid: string,
    name: string,
    address: Address,
    floorPlan: FloorPlan,
    imagePath: string,
    unitTerms: Term[],
    floorLevel: string
}

export type Address = {
    addressLine1: string,
    addressLine2: string,
    city: string,
    stateCode: string,
    zip: string
}


export type Property = {
    uuid: string,
    lat: number,
    lng: number,
    name: string,
    disabled?: boolean,
    address: Address,
    floorPlans: FloorPlan[],
    levels: Level[],
    business?: Business
}

export type AvailableFloorPlan = {
    floorPlan: FloorPlan,
    availableUnits: number
    minPrice: number,
    maxPrice: number
}

export type AvailableUnit = {
    unit: Unit,
    minPrice: number,
    maxPrice: number,
    availableDate: string,
    floorPlanDefaultTerms: Term[],
    deposit: number
}

export type AdditionalServiceDto = {
    floorPlanAdditionalServices: AdditionalService[],
    unitAdditionalServices: AdditionalService[]
}

export type AdditionalService = {
    uuid: string,
    price: string,
    name: string,
    description: string,
    units: string,
    optional: boolean
}

export type AppUser = {
    uuid: string,
    email: string,
    phone: string,
    firstName: string,
    lastName: string
}

export type QuoteLineItem = {
    createdAt: string,
    description: string,
    name: string,
    price: number,
    units: string,
    uuid: string,
}

export type Quote = {
    quoteNumber: string,
    startDate: string,
    termLength: number,
    termUnits: string,
    uuid: string,
    createdAt: string,
    quoteLineItems: QuoteLineItem[];
    appUser: AppUser,
    unit: Unit,
    validForDays: number

}

export type QuoteDto = {
    quote: Quote,
    agent: AppUser
}

export type Term = {
    uuid: string,
    price: string,
    length: string,
    units: string
}

export type FloorPlan = {
    uuid?: string,
    name: string,
    description: string,
    property: Property,
    bed: number,
    bath: number,
    occupancy: number,
    sqft: number,
    imageUrl: string
    type: string
}

export type Lease = {
    uuid: string,
    monthlyAmount: number,
    startDate: string,
    depositAmount: number,
    dueDate: number,
    endDate: string,
    isMonthToMonth: boolean
}

export type Level = {
    id: number,
    name: string
}

export type PropertyGroup = {
    uuid?: number,
    name: string,
}

export type PropertyGroupExt = {
    uuid?: number,
    name: string,
    properties: Property[]
}

export type Prospect = {
    id: number,
    property: string,
    firstName: string,
    middleInitial: string,
    lastName: string,
    email: string,
    phone: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zip: string,
    desiredMoveInDate: string,
    gender: string,
    desiredFloorPlan: string,
    desiredLevel: string,
    numberOfPeople: string,
    numberOfPets: string,
    priceRangeFrom: string,
    priceRangeTo: string,
    appointmentDate: string,
    expirationDate: string,
    notes: string
}

export type MenuItem = {
    label: string,
    path: string,
    menuItems: MenuItem[],
    icon: string,
    weight: number
}

export type Portfolio = {
    uuid: string,
    name: string,
    payoutFlowType: string,
    properties: Property[],
    business?: Business
}

export type Business = {
    uuid?: string,
    name: string,
    phone: string,
    person?: Person
    typeOfBusiness: string,
    businessStructure: string,
    address: Address,
    bankAccount?: BankAccount
}

export type Person = {
    firstName: string,
    lastName: string,
    maidenName: string
}

export type PortfolioExt = {
    portfolio: Portfolio,
    setupNeeded: string[],
}

export type IavToken = {
    token: string
}

export type Payment ={
    uuid: string,
    amount: number,
    createdAt: string,
    manualOverride: boolean,
    status: string,
    method: string
}

export type PaymentSource = {
    id: string,
    name: string,
    bankName: string,
    type: string,
    bankAccountType: string
}


export type Invoice = {
    uuid: string,
    billToAddress: Address,
    billToName: string,
    remitToAddress: Address,
    remitToName: string,
    invoiceDate: string,
    invoiceNumber: string,
    paymentDueDate: string,
    status: string,
    lineItems: LineItem[],
    total: number,
    totalTax: number,
    paidDate: string,
    payments: Payment[],
    facilitatorName: string,
    facilitatorAddress: Address
}

export type IdempotentKey = {
    key: string
}

export type LineItem = {
    uuid: string,
    price: number,
    quantity: number,
    description: string,
    taxAmount: number
}

export type BankAccount = {
    lastFour?: string,
    token?: string,
    accountHolderName?: string,
    accountHolderType?: string,
    routingNumber?: string,
    accountNumber?: string
}

export type Application = {
    uuid: string,
    status: ApplicationStatus
    unit: Unit,
    applicants: Applicant[],
    pets: Pet[],
    vehicles: Vehicle[],
    emergencyContacts: EmergencyContact[],
    trackingId: string
}

export type EmergencyContact = {
    name: string,
    email: string,
    phone: string
}

export type ApplicantAddress = {
    uuid: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zip: string,
    landLordName: string,
    landLordPhone: string,
    rentAmount: number,
    moveInDate: string,
    isCurrentAddress: boolean,
    moveOutDate?: string
}

export type Applicant = {
    uuid: string,
    applicationId?: string,
    applicantType: ApplicantType,
    firstName: string,
    middleInitial: string,
    lastName: string,
    maidenName: string,
    email: string,
    phone: string,
    ssn: string,
    dlNumber: string,
    dlState: string,
    smoke: boolean,
    gender: string,
    primary: boolean,
    applicantAddresses: ApplicantAddress[],
    employmentIncomeSources: Employment[]
}

export type Employment = {
    uuid: string,
    employerName: string,
    employmentContactPerson: string,
    employerPhone: string,
    occupation: string,
    monthlyGrossIncome: number,
    startDate: string,
    noEndDate: boolean,
    endDate?: string
}

export enum ApplicationStatus {
    in_progress = "in_progress", submitted = "submitted", approved = "approved", cancelled = "cancelled"
}

export enum ApplicantType {
    guarantor = "guarantor", occupant = "occupant", cooccupant = "cooccupant"
}

export enum Gender {
    male = "male", female = "female", prefer_not_to_say = "prefer_not_to_say",
}

export enum PetType {
    dog = "dog", cat = "cat", other = "other"
}

export type Pet = {
    uuid: string,
    petType: PetType,
    name: string,
    breed: string,
    weight: number,
    color: string,
    age: number,
    petLocation: string,
    declawed: boolean,
    gender: Gender,
    neutered: boolean,
    vaccinationsUpToDate: boolean,
    otherPetTypeDescription: string,
    description: string
}

export enum VehicleType {
    SUV = "SUV", Truck = "Truck", Car = "Car", Van = "Van", Compact = "Compact"
}

export type Vehicle = {
    uuid: string,
    type: VehicleType,
    make: string,
    model: string,
    year: number,
    color: string,
    licensePlate: string,
    registeredState: string
}

export type User = {
    uuid: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    lastLogin: string,
    tags: UserTag[],
    communicationPreferences: string[],
    actions: UserAction[],
    apps: App[]
}

export type App = {
    name: string,
    uri: string
}

export type UserAction = {
    code: string,
    description: string
}

export type CaptchaResponse = {
    uuid: string,
    image: string
}

export type UserTag = {
    name: string,
    level: string
}
