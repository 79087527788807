import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import './index.css'
import {useLocation} from "react-router-dom";
import {
    EmailRegex,
    FirstNameRegex,
    formInputValidate,
    formPasswordValidateMessages,
    GenericInputRegex,
    LastNameRegex,
    PasswordFormRegex,
    PhoneNumberRegex
} from "../../types";
import Input from "../Form/Input";
import {useParams} from "react-router";
import {getInvitedAppUser, setupInvitedAppUser} from "../../api";

const Invite: React.FunctionComponent = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const location = useLocation();
    const {appUserId} = useParams();
    const [smsChecked, setSmsChecked] = useState(true);
    const [emailChecked, setEmailChecked] = useState(true);
    const queryParams = new URLSearchParams(location.search);
    const [authId, setAuthId] = useState("");
    const [loading, setLoading] = useState(false);
    const doFailureMessage = (message: string) => {
        setMessage(message);
        setIsError(true);
    }

    useEffect(() => {
        document.body.style.background = '#353D40';
        setAuthId(queryParams.get("auth_id") || "")
    }, [queryParams]);

    useEffect(() => {
        getInvitedAppUser(appUserId).then((a) => {
            if (a.email.length > 0) {
                setEmail(a.email);
            }
        })
    }, [appUserId]);

    const onsubmit = (e: FormEvent) => {
        e.preventDefault();
        let commPrefs: string[] = [];
        if (emailChecked) {
            commPrefs.push("email");
        }
        if (smsChecked) {
            commPrefs.push("sms")
        }
        setLoading(true);
        setupInvitedAppUser({
            uuid: appUserId,
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            communicationPreferences: commPrefs
        }).then((r) => {
            let queryParams = window.location.search;
            if (!queryParams) {
                queryParams += "?"
            } else {
                queryParams += "&"
            }
            queryParams += "email=" + encodeURIComponent(email) + "&userId=" + appUserId
            if (!authId) {
                queryParams += "&auth_id=" + authId
            }
            console.error(queryParams);
            window.location.href = "/a/confirm-email" + queryParams;
        }).catch((e) => {
            doFailureMessage(e.message);
            console.error(e);
        }).finally(() => setLoading(false));
    }

    return (
        <>
            <main className="form-signup text-center">
                <form onSubmit={onsubmit}>
                    <a href={"/a/login"}>
                        <img className="mb-4" src="/assets/dist/img/propertymq_logo.png" alt="" width="300"/>
                    </a>
                    <h1 className="h3 mb-3 fw-normal">Please finalize your account setup</h1>
                    <div className="form-floating first-input no-pointer">
                        <Input disabled={false}
                               type={"email"}
                               required={true}
                               id={"field1"}
                               value={email}
                               setValue={() => {
                               }}
                               label={""}
                               validation={EmailRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={EmailRegex.helpText}
                               placeholder={""}
                               labelBottom={true}
                        />
                    </div>
                    <div className="form-floating middle-input">
                        <Input disabled={false}
                               type={"password"}
                               required={true}
                               id={"field2"}
                               value={password}
                               setValue={setPassword}
                               label={"Password"}
                               validation={PasswordFormRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={formPasswordValidateMessages(password).join(' ')}
                               placeholder={""}
                               labelBottom={true}
                        />
                    </div>
                    <div className="form-floating middle-input">
                        <Input disabled={false}
                               type={"text"}
                               required={true}
                               id={"field3"}
                               value={firstName}
                               setValue={setFirstName}
                               label={"First name"}
                               validation={FirstNameRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={FirstNameRegex.helpText}
                               placeholder={""}
                               labelBottom={true}
                        />
                    </div>
                    <div className="form-floating middle-input">
                        <Input disabled={false}
                               type={"text"}
                               required={true}
                               id={"field4"}
                               value={lastName}
                               setValue={setLastName}
                               label={"Last name"}
                               validation={LastNameRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={LastNameRegex.helpText}
                               placeholder={""}
                               labelBottom={true}
                        />
                    </div>

                    <div className="form-floating last-input">
                        <Input disabled={false}
                               type={"text"}
                               required={true}
                               id={"field5"}
                               value={phone}
                               setValue={setPhone}
                               label={"Phone"}
                               validation={PhoneNumberRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={PhoneNumberRegex.helpText}
                               placeholder={""}
                               labelBottom={true}
                        />
                    </div>
                    <div className={"form-floating cm_prefs"}>
                        <h5>Communication Preferences</h5>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                   checked={emailChecked} onChange={(e) => setEmailChecked(e.target.checked)}/>
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Email
                            </label>
                        </div>
                        <div className="form-check mb-2">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                                   checked={smsChecked} onChange={(e) => setSmsChecked(e.target.checked)}/>
                            <label className="cm_prefs" htmlFor="flexCheckChecked">
                                SMS
                            </label>
                        </div>
                    </div>
                    <button disabled={loading} className="w-100 btn btn-lg signup" type="submit">
                        <span hidden={loading}>
                        Submit
                        </span>
                        <span hidden={!loading}>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Submitting...
                        </span>
                    </button>
                    <div hidden={!message}
                         className={(isError) ? "alert alert-danger mt-3 mb-0" : "alert alert-success mt-3 mb-0"}
                         role="alert">
                        {message}
                    </div>
                    <p className="mt-5 mb-3 text-muted">&copy; 2015–2023</p>
                </form>
            </main>
        </>
    );
}

export default Invite;