import {CaptchaResponse, InvitedAppUser, User,} from "./types";
import {currentSession} from "./Auth";


const coreApiEndpoint = (window.location.hostname === "localhost" && parseInt(window.location.port) >= 3000 && parseInt(window.location.port) < 4000)
    ? (window.location.protocol + '//' + window.location.hostname + ":8100")
    : (window.location.protocol + '//' + window.location.host);

enum HttpMethod {
    GET = "GET",
    HEAD = "HEAD",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
    OPTIONS = "OPTIONS",
    TRACE = "TRACE"
}

const serialize = (obj: any) => {
    const str = [];
    for (const p in obj)
        if (obj.hasOwnProperty(p)) {
            if (obj[p] && obj[p].length > 0) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    return str.join("&");
}

function handleResponse(response: Response) {
    if (response.ok) {
        return response.json();
    } else {
        return response.json().then(function (error) {
            throw error;
        });
    }
}


function handleNetworkError(error: Error) {
    throw error;
}


type getUser = () => Promise<User>;

export const getUser: getUser = (async () => {
    return currentSession()
        .then((r) => {
            return fetch(coreApiEndpoint + "/users/me", {
                mode: 'cors',
                method: HttpMethod.GET,
                credentials: 'include',
                headers: {
                    Authorization: 'Bearer ' + r.access_token,
                    Pragma: 'no-cache'
                }
            }).then(handleResponse, handleNetworkError);
        }).catch((e) => {
            console.error(e);
            window.location.href = '/a/logout';
        })
});

type UpdateUserBody = {
    firstName: string,
    lastName: string,
    phone: string,
    communicationPreferences: string[]
}

type updateUser = (userId: string, body: UpdateUserBody) => Promise<User>;

export const updateUser: updateUser = (async (userId, body) => {
    return currentSession()
        .then((r) => {
            return fetch(coreApiEndpoint + "/users/" + userId, {
                mode: 'cors',
                method: HttpMethod.PUT,
                credentials: 'include',
                headers: {
                    Authorization: 'Bearer ' + r.access_token,
                    Pragma: 'no-cache',
                    'Content-Type': "application/json",
                },
                body: JSON.stringify(body)
            }).then(handleResponse, handleNetworkError);
        }).catch((e) => {
            console.error(e);
            window.location.href = '/a/logout';
        })
});

type CreateUserBody = {
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    password: string,
    business: string,
    captchaId: string,
    captchaAnswer: string,
    role: string
}

type createUser = (body: CreateUserBody) => Promise<User>;

export const createUser: createUser = (async (body) => {
    return fetch(coreApiEndpoint + "/users", {
        mode: 'cors',
        method: HttpMethod.POST,
        headers: {
            Pragma: 'no-cache',
            'Content-Type': "application/json",
        },
        body: JSON.stringify(body)
    }).then(handleResponse, handleNetworkError);
});

type ResetPasswordCodeRequestBody = {
    email: string,
    captchaId: string,
    captchaAnswer: string
}

type getResetPasswordCode = (body: ResetPasswordCodeRequestBody) => Promise<Response>;

export const getResetPasswordCode: getResetPasswordCode = (async (body) => {
    return fetch(coreApiEndpoint + "/users/send-reset-password-code", {
        mode: 'cors',
        method: HttpMethod.POST,
        headers: {
            Pragma: 'no-cache',
            'Content-Type': "application/json",
        },
        body: JSON.stringify(body)

    });
});

type getEmailConfirmationCode = (userId: string) => Promise<Response>;

export const getEmailConfirmationCode: getEmailConfirmationCode = (async (userId) => {
    return fetch(coreApiEndpoint + "/users/" + userId + "/send-confirm-email-code", {
        mode: 'cors',
        method: HttpMethod.GET,
        headers: {
            Pragma: 'no-cache',
            'Content-Type': "application/json",
        }
    }).catch((e) => {
        console.error(e);
        throw e;
    });
});

type confirmEmail = (userId: string, code: string) => Promise<Response>;

export const confirmEmail: confirmEmail = (async (userId, code) => {
    return fetch(coreApiEndpoint + "/users/" + userId + "/confirm-email/" + code, {
        mode: 'cors',
        method: HttpMethod.POST,
        headers: {
            Pragma: 'no-cache',
            'Content-Type': "application/json",
        }
    }).catch((e) => {
        console.error(e);
        throw e;
    })
});

type createCaptcha = () => Promise<CaptchaResponse>;

export const createCaptcha: createCaptcha = (async () => {
    return fetch(coreApiEndpoint + "/captchas", {
        mode: 'cors',
        method: HttpMethod.POST,
        headers: {
            Pragma: 'no-cache',
        }

    }).then(handleResponse, handleNetworkError);
});

type ResetPasswordRequestBody = {
    email: string,
    captchaId: string,
    captchaAnswer: string,
    code: string,
    password: string
}

type resetPassword = (body: ResetPasswordRequestBody) => Promise<Response>;

export const resetPassword: resetPassword = (async (body) => {
    return fetch(coreApiEndpoint + "/users/reset-password", {
        mode: 'cors',
        method: HttpMethod.POST,
        headers: {
            Pragma: 'no-cache',
            'Content-Type': "application/json",
        },
        body: JSON.stringify(body)

    });
});

type getInvitedAppUser = (uuid: string) => Promise<InvitedAppUser>;

export const getInvitedAppUser: getInvitedAppUser = (async (uuid) => {
    return fetch(coreApiEndpoint + "/users/" + uuid + "/invitation-fetch", {
        mode: 'cors',
        method: HttpMethod.GET,
        headers: {
            Pragma: 'no-cache'
        }
    }).then(handleResponse, handleNetworkError).catch((e) => {
        window.location.href = "/a/login"
        console.error(e);
    })
});

type SetupUserBody = {
    uuid: string,
    email: string
    password: string,
    firstName: string,
    lastName: string,
    phone: string,
    communicationPreferences: string[]
}

type setupInvitedAppUser = (body: SetupUserBody) => Promise<User>

export const setupInvitedAppUser: setupInvitedAppUser = (async (body) => {
    return fetch(coreApiEndpoint + "/users/" + body.uuid + "/invitation-activate", {
        mode: 'cors',
        method: HttpMethod.POST,
        headers: {
            Pragma: 'no-cache',
            'Content-Type': "application/json",
        },
        body: JSON.stringify(body)
    }).then(handleResponse, handleNetworkError)
})