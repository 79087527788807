import * as React from 'react';
import {useEffect, useState} from 'react';
import './index.css'
import {APP_PROFILE_PATH} from "../../types";
import {Link, useHistory, useLocation} from "react-router-dom";
import {AUTH_ACCESS_TOKEN, AUTH_REFRESH_TOKEN, coreApiEndpoint, currentSession} from "../../Auth";

const Login: React.FunctionComponent = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [showInvalid, setShowInvalid] = useState(false);
    const [showNonActive, setShowNonActive] = useState(false);
    const [authId, setAuthId] = useState("");
    const history = useHistory();
    const location = useLocation();
    const fullPath = location.pathname;
    const queryParams = new URLSearchParams(location.search);

    const [doHomeRedirect, setDoHomeRedirect] = useState(false);
    const [doProfileRedirect, setDoProfileRedirect] = useState(false);
    const [doConfirmEmailRedirect, setDoConfirmEmailRedirect] = useState(false);


    // useEffect(() => {
    //     if (doHomeRedirect) {
    //         const redirectUri = queryParams.get("redirect_uri");
    //         if (redirectUri) {
    //             window.location.href = redirectUri+"?access_token=" + localStorage.getItem(AUTH_ACCESS_TOKEN) + "&refresh_token=" + localStorage.getItem(AUTH_REFRESH_TOKEN)
    //         }
    //         else {
    //             history.push({
    //                 pathname: APP_PROFILE_PATH
    //             })
    //         }
    //     }
    // }, [doHomeRedirect, history, queryParams]);

    // useEffect(() => {
    //     if (doConfirmEmailRedirect) {
    //         history.push({
    //             pathname: APP_CONFIRM_EMAIL_PATH
    //         });
    //     }
    // }, [doConfirmEmailRedirect, history]);

    useEffect(() => {
        const redirectUri = queryParams.get("redirect_uri");
        let authIdQp = queryParams.get("auth_id");
        if (redirectUri && !authIdQp) {
            window.location.href = redirectUri;
            return;
        }
        setAuthId(authIdQp || "");
        let errorMessage = queryParams.get("error");
        setError(errorMessage || "")
        if (error === "invalid-credentials") {
            setShowInvalid(true);
        } else if (error === "nonactive-account") {
            setShowNonActive(true);
        }
        currentSession().then(session => {
            if (!authIdQp) {
                setDoProfileRedirect(true);
            }

        }).catch(e => {
        })
    }, [queryParams]);

    useEffect(() => {
        if (doProfileRedirect) {
            const redirectUri = queryParams.get("redirect_uri");
            if (redirectUri) {
                window.location.href = redirectUri + "?access_token=" + localStorage.getItem(AUTH_ACCESS_TOKEN) + "&refresh_token=" + localStorage.getItem(AUTH_REFRESH_TOKEN)
            } else {
                history.push({
                    pathname: APP_PROFILE_PATH
                })
            }
        }


    }, [doProfileRedirect, history, queryParams]);


    return (
        <>
            <main className="form-signin text-center">
                <form method={"post"} action={coreApiEndpoint + "/login"}>
                    <img className="mb-4" src="/assets/dist/img/propertymq_logo.png" alt="" width="300"/>
                    <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

                    <div className="form-floating">
                        <input type="email" name={"email"} className="form-control" id="floatingInput"
                               placeholder="name@example.com"
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}/>
                        <label htmlFor="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating">
                        <input type="password" name="password" className="form-control" id="floatingPassword"
                               placeholder="Password"
                               value={password}
                               onChange={(e) => setPassword(e.target.value)}/>
                        <label htmlFor="floatingPassword">Password</label>
                    </div>
                    <input type={"hidden"} name="auth_id" id="auth_id"
                           value={authId}
                    />
                    <button className="w-100 btn btn-lg signin" type="submit">Sign in</button>
                    <div className={"mt-1"}>
                        <Link className={"text-white"} to={"/a/reset-password" + window.location.search}>Forgot
                            password?</Link>
                        <Link className={"text-white ms-3"} to={"/a/signup" + window.location.search}>Create
                            account</Link>
                    </div>
                    <div hidden={!showInvalid} className="alert alert-danger mt-3" role="alert">
                        Login failed. Username and/or password is invalid.
                    </div>
                    <div hidden={!showNonActive} className="alert alert-danger mt-3" role="alert">
                        Account Activation Required.
                        <br/>
                        Please visit your email for further instructions.
                    </div>
                    <p className="mt-5 mb-3 text-muted">&copy; 2015–2022</p>
                </form>
            </main>
        </>
    );
}

export default Login;