import * as React from 'react';
import {useEffect, useState} from 'react';
import './index.css'
import {useHistory, useLocation} from "react-router-dom";
import {confirmEmail, getEmailConfirmationCode} from "../../api";

const ConfirmEmail: React.FunctionComponent = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [userId, setUserId] = useState("");
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [authId, setAuthId] = useState("");
    const history = useHistory();
    const [confirmationCode, setConfirmationCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingCode, setLoadingCode] = useState(false);
    const [message, setMessage] = useState("");
    const [isMessageError, setIsMessageError] = useState(false);
    const [doRedirect, setDoRedirect] = useState(false);

    useEffect(() => {
        document.body.style.background = '#353D40';
        if(queryParams) {
            setEmail(queryParams.get("email") || "");
            setUserId(queryParams.get("userId") || "");
            setAuthId(queryParams.get("auth_id") || "")
        }
    }, [queryParams]);

    useEffect(() => {
        if (userId) {
            getEmailConfirmationCode(userId)
                .then(async (r) => {
                    if (!r.ok) {
                        let b = await r.json();
                        doFailureMessage(b.message);
                    }
                })
                .catch((e) => {
                    console.error(e)
                    doFailureMessage("Confirmation code failed to send.");
                })
                .finally(() => setLoadingCode(false));
        }
    }, [userId]);


    const doFailureMessage = (message: string) => {
        setMessage(message);
        setIsMessageError(true);
    }

    const doMessage = (message: string) => {
        setMessage(message);
        setIsMessageError(false);
    }

    const clearMessage = () => {
        setMessage("");
        setIsMessageError(false);
    }


    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!email || !userId || !code) {
            return;
        }
        setLoading(true);
        confirmEmail(userId, code)
            .then(async (r) => {
                if (r.ok) {
                    let queryParams = "";
                    if(authId) {
                        queryParams = "?auth_id=" + authId;
                    }
                    window.location.href = '/a/login' + queryParams;
                } else {
                    let b = await r.json();
                    doFailureMessage(b.message);
                    setCode("");
                }
            })
            .catch((e) => {
                doFailureMessage(e.message);
                setCode("");
            })
            .finally(() => setLoading(false));
    };

    const onResendCode = (e: React.MouseEvent) => {
        e.preventDefault();
        clearMessage()
        if (email == null || userId == null) {
            return;
        }
        setLoadingCode(true);
        getEmailConfirmationCode(userId)
            .then(async (r) => {
                if (r.ok) {
                    doMessage("Confirmation code successfully sent.");
                } else {
                    let b = await r.json();
                    doFailureMessage(b.message);
                }
            })
            .catch((e) => {
                console.error(e)
                doFailureMessage("Confirmation code failed to send.");
            })
            .finally(() => setLoadingCode(false));
    }

    return (
        <>
            <main className="form-confirm-email text-center">
                <form onSubmit={onSubmit}>
                    <a href={"/a/login"}>
                        <img className="mb-4" src="/assets/dist/img/propertymq_logo.png" alt="" width="300"/>
                    </a>
                    <h1 className="h3 mb-3 fw-normal text-white">Confirm Email</h1>
                    <p className={"text-white"}>Check your email for a confirmation code from no-reply@notification.propertymq.com.</p>
                    <div className="form-floating">
                        <input disabled={true} type="email" className="form-control" id="floatingInput"
                               value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <label htmlFor="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating">
                        <input type="text" className="form-control code" id="floatingInput"
                               placeholder="Code"
                               required={true}
                               value={code} onChange={(e) => setCode(e.target.value)}/>
                        <label htmlFor="floatingInput">Confirmation code</label>
                    </div>

                    <div className="checkbox mb-3">
                    </div>
                    <button className="w-100 btn btn-lg signin mb-3" type="submit">Confirm email</button>
                    <button className="w-100 btn btn-lg btn-outline-light" type="button"
                            onClick={onResendCode}>Resend code
                    </button>
                    <div hidden={!message}
                         className={(isMessageError) ? "alert alert-danger mt-3 mb-0" : "alert alert-success mt-3 mb-0"}
                         role="alert">
                        {message}
                    </div>
                </form>


                <p className="mt-5 mb-3 text-muted">&copy; 2015–2023</p>
            </main>
        </>
    );
}

export default ConfirmEmail;