import Cookies from 'js-cookie';

let jwt_decode = require('jwt-decode');


export const coreApiEndpoint = (window.location.hostname === "localhost" && parseInt(window.location.port) >= 3000 && parseInt(window.location.port) < 4000)
    ? (window.location.protocol + '//' + window.location.hostname + ":8100")
    : (window.location.protocol + '//' + window.location.host);


export const AUTH_ACCESS_TOKEN = "stackleader.auth.access_token";
export const AUTH_REFRESH_TOKEN = "stackleader.auth.refresh_token";
export const AUTH_EXPIRES_IN = "stackleader.auth.expires_in";
export const AUTH_SCOPE = "stackleader.auth.scope";
export const AUTH_TOKEN_TYPE = "stackleader.auth.token_type";
export const AUTH_CLOCK_START = "stackleader.auth.clock_start";

const AUTH_CODE_EXPIRED_TOKEN = "stackleader.auth.code.expired_token"

enum HttpMethod {
    GET = "GET", HEAD = "HEAD", POST = "POST", PUT = "PUT", PATCH = "PATCH", DELETE = "DELETE", OPTIONS = "OPTIONS", TRACE = "TRACE"
}

function handleResponse(response: Response) {
    if (response.ok) {
        return response.json();
    } else {
        return response.json().then(function (error) {
            throw error;
        });
    }
}


function handleNetworkError(error: Error) {
    throw error;
}

export type OAuthSession = {
    access_token: string,
    token_type: string,
    expires_in: string,
    refresh_token: string,
    scope: string
}

type currentSession = () => Promise<OAuthSession>;

export const currentSession: currentSession = (() => {
    const queryParams = new URLSearchParams(window.location.search);
    let token: any = queryParams.get("access_token");
    if (!token) {
        token = Cookies.get("pmq.access_token");
    }

    if (!token) {
        return new Promise<OAuthSession>(function (resolve, reject) {
            reject({
                message: "expired token",
                code: AUTH_CODE_EXPIRED_TOKEN
            })
        })
    }
    let decoded = jwt_decode(token);
    console.log(decoded);
    let clockStart = decoded.iat;
    const elapsedTime = Math.floor((Date.now() / 1000) - clockStart);
    console.log("elapsed time: " + elapsedTime);
    let clockEnd = decoded.exp;
    const timeRemaining = Math.floor(clockEnd - (Date.now() / 1000));
    console.log("remaining time: " + timeRemaining);
    if (timeRemaining > 60) {
        return new Promise<OAuthSession>(function (resolve, reject) {
                resolve({
                    access_token: token || "",
                    expires_in: "",
                    scope: "",
                    refresh_token: "",
                    token_type: ""

                })
            }
        );
    }

    return new Promise<OAuthSession>(function (resolve, reject) {
        reject({
            message: "expired token",
            code: AUTH_CODE_EXPIRED_TOKEN
        })
    })

});

