import * as React from 'react';
import './container.css';
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import {useEffect} from "react";
import Login from "../../Components/Login";
import Signup from "../../Components/Signup";
import Invite from "../../Components/Invite";
import ResetPassword from "../../Components/ResetPassword";
import Logout from "../../Components/Logout";
import ConfirmEmail from "../../Components/ConfirmEmail";



const PublicMainContainer: React.FunctionComponent = () => {
    let {path} = useRouteMatch();

    useEffect(() => {
        document.body.style.background = '#353D40';
    }, [])

    return (<div className={"public"}>
        <Switch>
            <Route path={path + "/login"}>
                <Login/>
            </Route>
            <Route path={path + "/logout"}>
                <Logout/>
            </Route>
            <Route path={path + "/signup"}>
                <Signup/>
            </Route>
            <Route exact={false} path={path + "/invite/:appUserId"}>
                <Invite/>
            </Route>
            <Route path={path + "/reset-password"}>
                <ResetPassword/>
            </Route>

            <Route path={path + "/confirm-email"}>
                <ConfirmEmail/>
            </Route>

        </Switch>
    </div>);
};

export default PublicMainContainer;