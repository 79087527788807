import * as React from 'react';
import './index.css'
import {FormEvent, useEffect, useState} from "react";
import {getUser, updateUser} from "../../api";
import {
    App,
    EmailRegex,
    FirstNameRegex,
    formInputValidate,
    LastNameRegex,
    PhoneNumberRegex,
    UserAction,
    UserTag
} from "../../types";
import Input from "../Form/Input";
import {Link} from "react-router-dom";
import phoneFormatter from "phone-formatter";


const Profile: React.FunctionComponent = () => {

    const [userId, setUserId] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [tags, setTags] = useState<UserTag[]>([]);
    const [smsChecked, setSmsChecked] = useState(false);
    const [emailChecked, setEmailChecked] = useState(false);
    const [actions, setActions] = useState<UserAction[]>([]);
    const [apps, setApps] = useState<App[]>([]);

    useEffect(() => {
        document.body.style.background = '#FFF';
    }, [])

    const onsubmit = (e: FormEvent) => {
        e.preventDefault();
        let commPrefs: string[] = [];
        if (emailChecked) {
            commPrefs.push("email");
        }
        if (smsChecked) {
            commPrefs.push("sms")
        }
        updateUser(userId, {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            communicationPreferences: commPrefs
        }).then((r) => {

        }).catch((e) => console.error(e));
    }

    useEffect(() => {
        getUser()
            .then((u) => {
                setUserId(u.uuid)
                setEmail(u.email);
                setFirstName(u.firstName);
                setLastName(u.lastName);
                setPhone(phoneFormatter.format(u.phone, "NNN-NNN-NNNN"));
                setTags(u.tags);
                setActions(u.actions);
                setApps(u.apps);
                if (u.communicationPreferences) {
                    u.communicationPreferences.forEach((p) => {
                        if (p === "sms") {
                            setSmsChecked(true);
                        }
                        if (p === "email") {
                            setEmailChecked(true);
                        }
                    })
                }
            }).catch((e) => console.error(e));
    }, [])

    const getPathByCode = (code: string) => {
        switch (code) {
            case "account-activation-required":
                return "/m/activation-required";
            case "email-verification-required":
                return "/m/confirm-email";
            default:
                return "/m/profile";
        }
    }

    const queryParams = new URLSearchParams(window.location.search);
    let redirectUri = queryParams.get("redirect_uri");
    if(!redirectUri?.startsWith("https://tenant.propertymq.com/")
        && !redirectUri?.startsWith("https://manage.propertymq.com/")
        && window.location.hostname !== "localhost") {
        redirectUri = "/m/profile";
    }

    return (
        <div className={"profile"}>
            <header className="p-3 bg-theme-1 text-white navigation">
                <div className="container">
                    <div
                        className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <a href={redirectUri ? redirectUri : "/m/profile"}
                           className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none"><img
                            src="/assets/dist/img/propertymq_logo.png" style={{width: 200}}/></a>
                        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">

                        </ul>
                        <div className="text-end">
                            <a href="/a/logout">
                                <button type="button" className="btn btn-signup bg-theme-4 border-theme-4">Logout
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
            <nav className="py-2 bg-light border-bottom">
                <div className="container d-flex flex-wrap">
                    <ul className="nav me-auto">
                        <li className="nav-item navigation-not-selected"><a className="nav-link link-dark px-2"
                                                                            href="https://tenant.propertymq.com/p/t/account-summary">Accounts</a>
                        </li>
                    </ul>
                </div>
            </nav>
            <main id="main" style={{marginTop: 75}}>
                <div className="container px-4 py-5">
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <h3>Manage Profile</h3>
                        </div>
                        <div className={"col-12 mb-3"}>
                            {
                                tags.map((t, index) => (
                                    <span key={t.name + "_" + index} className={"badge bg-" + t.level.toLowerCase()}
                                          style={{marginRight: 10}}>{t.name}</span>
                                ))
                            }
                        </div>
                    </div>
                    <form onSubmit={onsubmit}>
                        <div className={"row"}>

                            <div className={"col-md-4"}>
                                <h5>Account Information</h5>
                                <div className="mb-3">
                                    <Input disabled={true}
                                           type={"email"}
                                           required={true}
                                           id={"field1"}
                                           value={email}
                                           setValue={setEmail}
                                           label={"Email address"}
                                           validation={EmailRegex.expression}
                                           doValidate={formInputValidate}
                                           invalidFeedbackText={EmailRegex.helpText}
                                           placeholder={""}
                                    />

                                </div>
                                <div className="mb-3">
                                    <Input disabled={false}
                                           type={"text"}
                                           required={true}
                                           id={"field2"}
                                           value={firstName}
                                           setValue={setFirstName}
                                           label={"First name"}
                                           validation={FirstNameRegex.expression}
                                           doValidate={formInputValidate}
                                           invalidFeedbackText={FirstNameRegex.helpText}
                                           placeholder={""}
                                    />

                                </div>
                                <div className="mb-3">
                                    <Input disabled={false}
                                           type={"text"}
                                           required={true}
                                           id={"field3"}
                                           value={lastName}
                                           setValue={setLastName}
                                           label={"Last name"}
                                           validation={LastNameRegex.expression}
                                           doValidate={formInputValidate}
                                           invalidFeedbackText={LastNameRegex.helpText}
                                           placeholder={""}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Input disabled={false}
                                           type={"phone"}
                                           required={true}
                                           id={"field3"}
                                           value={phone}
                                           setValue={setPhone}
                                           label={"Phone"}
                                           validation={PhoneNumberRegex.expression}
                                           doValidate={formInputValidate}
                                           invalidFeedbackText={PhoneNumberRegex.helpText}
                                           placeholder={""}
                                    />
                                </div>
                                <h5>Communication Preferences</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                           checked={emailChecked} onChange={(e) => setEmailChecked(e.target.checked)}/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Email
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                                           checked={smsChecked} onChange={(e) => setSmsChecked(e.target.checked)}/>
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        SMS
                                    </label>
                                </div>
                                <button type="submit" className="btn btn-primary me-3">Save</button>
                                <a href={"/a/reset-password?email=" + email}>
                                    <button type="button" className="btn btn-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-box-arrow-up-right"
                                             viewBox="0 0 16 16">
                                            <path fillRule="evenodd"
                                                  d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
                                            <path fillRule="evenodd"
                                                  d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"></path>
                                        </svg>
                                        <span className={"ms-1"}>Change Password</span>
                                    </button>
                                </a>
                            </div>
                            <div className={"col-md-4"}>

                                <h5>Apps</h5>
                                <div className="list-group">
                                    {
                                        apps.map((p, index) => (
                                            <button key={p.name + "_" + index} type="button"
                                                    className="list-group-item list-group-item-action"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href = p.uri;
                                                    }}>
                                                {p.name}
                                            </button>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={"col-md-4"}>
                                <div hidden={!actions || actions.length == 0}>
                                    <h5>Action Items</h5>
                                    <div className="list-group">
                                        {
                                            actions.map(p => (
                                                <Link to={getPathByCode(p.code)} className={"list-group-item-link"}>
                                                    <button key={p.code} type="button"
                                                            className="list-group-item list-group-item-action">
                                                        {p.description}
                                                    </button>
                                                </Link>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
}

export default Profile;