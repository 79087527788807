import * as React from 'react';
import './index.css'
import {useEffect, useState} from "react";
import {
    APP_CONFIRM_EMAIL_PATH,
    APP_PROFILE_PATH,
    AUTH_USER_EMAIL_KEY
} from "../../types";
import {Link, useHistory, useLocation} from "react-router-dom";
import {currentSession} from "../../Auth";

const ActivationRequired: React.FunctionComponent = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const history = useHistory();
    const location = useLocation();
    const fullPath = location.pathname;
    const queryParams = new URLSearchParams(location.search);

    const [doHomeRedirect, setDoHomeRedirect] = useState(false);
    const [doPortfoliosRedirect, setDoPortfoliosRedirect] = useState(false);
    const [doConfirmEmailRedirect, setDoConfirmEmailRedirect] = useState(false);


    useEffect(() => {
        document.body.style.background = '#353D40';
    }, [])


    return (
        <>
            <main className="activation-required text-center">
                <img className="mb-4" src="/assets/dist/img/propertymq_logo.png" alt="" width="300"/>
                <h1 className="h3 mb-3 font-weight-normal text-white">This account requires activation.</h1>
                <p>Contact your account administrator PropertyMQ at contact@propertymq.com.</p>
                <p className="mt-5 mb-3 text-muted">&copy; 2015–2022</p>
            </main>
        </>
    );
}

export default ActivationRequired;