import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import PublicMainContainer from "./Containers/PublicMainContainer";
import PrivateMainContainer from "./Containers/PrivateMainContainer";

function App() {
    return (
        <Router>
            <Route path={"/a"}>
                <PublicMainContainer/>
            </Route>
            <Route path={"/m"}>
                <PrivateMainContainer/>
            </Route>
        </Router>
    );
}

export default App;
