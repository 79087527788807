import * as React from 'react';
import './index.css'
import {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {
    APP_LOGIN_PATH,
    EmailRegex,
    FirstNameRegex,
    formInputValidate,
    formPasswordValidateMessages,
    GenericInputRegex,
    LastNameRegex, PasswordFormRegex, PhoneNumberRegex
} from "../../types";
import Input from "../Form/Input";
import {createCaptcha, createUser} from "../../api";
import {Link} from "react-router-dom";

const Signup: React.FunctionComponent = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [business, setBusiness] = useState("");
    const [phone, setPhone] = useState("");
    const [role, setRole] = useState("tenant");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [captcha, setCaptcha] = useState();
    const [captchaId, setCaptchaId] = useState("");
    const [captchaAnswer, setCaptchaAnswer] = useState("");
    const [doRedirect, setDoRedirect] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const doFailureMessage = (message: string) => {
        setMessage(message);
        setIsError(true);
    }

    const doMessage = (message: string) => {
        setMessage(message);
        setIsError(false);
    }

    const clearMessage = () => {
        setMessage("");
        setIsError(false);
    }

    const loadCaptcha = useCallback(() => {
        createCaptcha()
            .then((c) => {
                setCaptchaId(c.uuid)
                setCaptcha(c);
            })
            .catch((e) => console.error(e));
    }, []);

    useEffect(() => {
        loadCaptcha();
    }, [loadCaptcha])

    useEffect(() => {
        if (doRedirect) {
            history.push({
                pathname: APP_LOGIN_PATH
            });
        }
    }, [doRedirect, history]);


    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        createUser({
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            business: business,
            phone: phone,
            captchaId: captchaId,
            captchaAnswer: captchaAnswer,
            role: role
        }).then((r) => {
            let queryParams = window.location.search;
            if (!queryParams) {
                queryParams += "?email=" + encodeURIComponent(email) + "&userId=" + r.uuid
            } else {
                queryParams += "&email=" + encodeURIComponent(email) + "&userId=" + r.uuid
            }
            window.location.href = "/a/confirm-email" + queryParams;
        }).catch((e) => {
            doFailureMessage(e.message);
            setCaptchaAnswer("");
            loadCaptcha();
            console.error(e)
        });
    };


    return (
        <>
            <main className="form-signup text-center">
                <form onSubmit={onSubmit}>
                    <img className="mb-4" src="/assets/dist/img/propertymq_logo.png" alt="" width="300"/>
                    <h1 className="h3 mb-3 fw-normal">Please create an account</h1>
                    <p><Link to={"/a/login" +  window.location.search} className={"text-white"}>or try signing in</Link></p>
                    <div className="form-floating first-input">
                        <Input disabled={false}
                               type={"email"}
                               required={true}
                               id={"field1"}
                               value={email}
                               setValue={setEmail}
                               label={"Email address"}
                               validation={EmailRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={EmailRegex.helpText}
                               placeholder={""}
                               labelBottom={true}
                        />
                    </div>
                    <div className="form-floating middle-input">
                        <Input disabled={false}
                               type={"password"}
                               required={true}
                               id={"field2"}
                               value={password}
                               setValue={setPassword}
                               label={"Password"}
                               validation={PasswordFormRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={formPasswordValidateMessages(password).join(' ')}
                               placeholder={""}
                               labelBottom={true}
                        />
                    </div>
                    <div className="form-floating middle-input">
                        <Input disabled={false}
                               type={"text"}
                               required={true}
                               id={"field3"}
                               value={firstName}
                               setValue={setFirstName}
                               label={"First name"}
                               validation={FirstNameRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={FirstNameRegex.helpText}
                               placeholder={""}
                               labelBottom={true}
                        />
                    </div>
                    <div className="form-floating middle-input">
                        <Input disabled={false}
                               type={"text"}
                               required={true}
                               id={"field4"}
                               value={lastName}
                               setValue={setLastName}
                               label={"Last name"}
                               validation={LastNameRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={LastNameRegex.helpText}
                               placeholder={""}
                               labelBottom={true}
                        />
                    </div>

                    <div className="form-floating last-input">
                        <Input disabled={false}
                               type={"text"}
                               required={true}
                               id={"field5"}
                               value={phone}
                               setValue={setPhone}
                               label={"Phone"}
                               validation={PhoneNumberRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={PhoneNumberRegex.helpText}
                               placeholder={""}
                               labelBottom={true}
                        />
                    </div>

                    <div className="form-floating mt-3 mb-3"
                         style={{color: "#FFF"}}>
                        Captcha:
                        <img src={captcha?.image} style={{margin: 3, backgroundColor: "#CCC"}}/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             className="bi bi-arrow-clockwise" viewBox="0 0 16 16"
                             onClick={() => {
                                 loadCaptcha();
                             }}>
                            <path fillRule="evenodd"
                                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                            <path
                                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                        </svg>
                    </div>
                    <div className="form-floating mt-3 mb-3">
                        <Input
                            type={"text"}
                            id={"PasswordCaptcha"}
                            required={true}
                            value={captchaAnswer}
                            setValue={setCaptchaAnswer}
                            label={"Type characters in Captcha image above"}
                            validation={GenericInputRegex.expression}
                            doValidate={formInputValidate}
                            invalidFeedbackText={GenericInputRegex.helpText}
                            placeholder={"Type characters in Captcha image above"}
                            labelBottom={true}
                        />
                    </div>
                    <button className="w-100 btn btn-lg signup" type="submit">Sign up</button>
                    <div hidden={!message}
                         className={(isError) ? "alert alert-danger mt-3 mb-0" : "alert alert-success mt-3 mb-0"}
                         role="alert">
                        {message}
                    </div>
                    <p className="mt-5 mb-3 text-muted">&copy; 2015–2023</p>
                </form>
            </main>
        </>
    );
}

export default Signup;