import * as React from 'react';
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';

const Logout: React.FunctionComponent = () => {

    const queryParams = new URLSearchParams(window.location.search);

    const [logoutUri, setLogoutUri] = useState("/a/login");

    useEffect(() => {
        localStorage.clear();
        Cookies.remove("pmq.access_token")
        let redirect_uri = queryParams.get("redirect_uri");
        if(redirect_uri !== undefined && redirect_uri !== null && redirect_uri.length > 0) {
            setLogoutUri("/a/login?redirect_uri=" + redirect_uri);
        }

    }, [])


    return (<>
        <main className="form-signin text-center">
            <form>
                <img className="mb-4" src="/assets/dist/img/propertymq_logo.png" alt="" width="300"/>
                <h1 className="h3 mb-3 fw-normal">You are logged out. <br/>Thank you for using PropertyMQ!</h1>
                <Link to={logoutUri}>
                    <button className="w-100 btn btn-lg signin" type="button">Return to sign in</button>
                </Link>
                <p className="mt-5 mb-3 text-muted">&copy; 2015–2022</p>
            </form>
        </main>
    </>);
};

export default Logout;