import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import './index.css'
import {APP_LOGIN_PATH, CaptchaResponse, EmailRegex, formInputValidate, formPasswordValidateMessages, GenericInputRegex, PasswordFormRegex} from "../../types";
import {useHistory} from "react-router-dom";
import Input from "../Form/Input";
import {createCaptcha, getResetPasswordCode, resetPassword} from "../../api";

const ResetPassword: React.FunctionComponent = () => {

    const [email, setEmail] = useState("");
    const [sendEmailSuccess, setSendEmailSuccess] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [emailCodeCaptcha, setEmailCodeCaptcha] = useState<CaptchaResponse>();
    const [emailCodeCaptchaResponse, setEmailCodeCaptchaResponse] = useState("");
    const [passwordCaptcha, setPasswordCaptcha] = useState<CaptchaResponse>();
    const [passwordCaptchaResponse, setPasswordCaptchaResponse] = useState("");

    const [loading, setLoading] = useState(false);
    const [emailCodeMessage, setEmailCodeMessage] = useState("");
    const [isEmailCodeError, setIsEmailCodeError] = useState(false);
    const [resetPasswordMessage, setResetPasswordMessage] = useState("");
    const [isResetPasswordError, setIsResetPasswordError] = useState(false);
    const [doRedirect, setDoRedirect] = useState(false);
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    let emailParam = queryParams.get("email");

    useEffect(() => {
        if (doRedirect) {
            history.push({
                pathname: APP_LOGIN_PATH
            });
        }
    }, [doRedirect, history]);

    const loadEmailCodeCaptcha = useCallback(() => {
        createCaptcha()
            .then((c) => {
                setEmailCodeCaptcha(c);
            })
            .catch((e) => console.error(e));
    }, []);

    const loadPasswordCaptcha = useCallback(() => {
        createCaptcha()
            .then((c) => {
                setPasswordCaptcha(c);
            })
            .catch((e) => console.error(e));
    }, [])

    useEffect(() => {
        if (emailParam) {
            setEmail(emailParam);
        }
        loadEmailCodeCaptcha();
    }, []);

    useEffect(() => {
        if (sendEmailSuccess) {
            loadPasswordCaptcha();
        }
    }, [sendEmailSuccess]);


    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!passwordCaptcha) {
            return;
        }
        if(password !== confirmPassword) {
            setIsResetPasswordError(true);
            setResetPasswordMessage("Password does not match confirmation password.");
            return;
        }
        setIsResetPasswordError(false);
        setResetPasswordMessage("");
        setLoading(true);
        resetPassword({
                email: email,
                password: password,
                captchaAnswer: passwordCaptchaResponse,
                captchaId: passwordCaptcha?.uuid,
                code: confirmationCode
            }
        )
            .then(async (r) => {
                if (r.ok) {
                    window.location.href = "/a/login" + window.location.search;
                } else {
                    let body = await r.json();
                    setIsResetPasswordError(true);
                    setResetPasswordMessage(body.message);
                }
            })
            .catch((e) => {
                setIsResetPasswordError(true);
                setResetPasswordMessage(e.message);
            })
            .finally(() => setLoading(false));
    }

    const onRequestCode = (e: React.FormEvent) => {
        e.preventDefault();
        if (!emailCodeCaptcha) {
            return;
        }
        setIsEmailCodeError(false);
        setEmailCodeMessage("");
        setLoading(true);
        getResetPasswordCode({
            email: email,
            captchaId: emailCodeCaptcha.uuid,
            captchaAnswer: emailCodeCaptchaResponse
        }).then(async (r) => {
            if (r.ok) {
                setSendEmailSuccess(true);
                setEmailCodeMessage("Reset password code sent to your email");
            } else {
                setIsEmailCodeError(true);
                let body = await r.json();
                setEmailCodeMessage(body.message);
                loadEmailCodeCaptcha();
                setEmailCodeCaptchaResponse("")
            }
        })
            .catch(e => {
                console.error(e);
                setIsEmailCodeError(true);
                setEmailCodeMessage("Request failed");
                loadEmailCodeCaptcha();
                setEmailCodeCaptchaResponse("");
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <main className="form-reset-password text-center">
                <form onSubmit={onRequestCode}>
                    <img className="mb-4" src="/assets/dist/img/propertymq_logo.png" alt="" width="300"/>
                    <h1 className="h3 mb-3 fw-normal">Reset Password</h1>

                    <div className="form-floating">
                        <Input disabled={(emailParam || loading || sendEmailSuccess) ? true : false}
                               type={"email"}
                               id={"field1"}
                               required={true}
                               value={email}
                               setValue={setEmail}
                               label={"Email address"}
                               validation={EmailRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={EmailRegex.helpText}
                               placeholder={"Email"}
                               labelBottom={true}
                        />
                    </div>
                    <div hidden={!emailCodeCaptcha || sendEmailSuccess} className="form-floating mt-3"
                         style={{color: "#FFF"}}>
                        Captcha:
                        <img src={emailCodeCaptcha?.image} style={{margin: 3, backgroundColor: "#CCC"}}/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             className="bi bi-arrow-clockwise" viewBox="0 0 16 16"
                             onClick={() => {
                                 loadEmailCodeCaptcha();
                             }}>
                            <path fill-rule="evenodd"
                                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                            <path
                                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                        </svg>
                    </div>
                    <div hidden={sendEmailSuccess} className="form-floating mt-3">
                        <Input disabled={(loading || sendEmailSuccess) ? true : false}
                               type={"text"}
                               id={"EmailCodeCaptcha"}
                               required={true}
                               value={emailCodeCaptchaResponse}
                               setValue={setEmailCodeCaptchaResponse}
                               label={"Type characters in Captcha image above"}
                               validation={GenericInputRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={GenericInputRegex.helpText}
                               placeholder={"Type characters in Captcha image above"}
                               labelBottom={true}
                        />
                    </div>
                    <div hidden={!emailCodeMessage}
                         className={(isEmailCodeError) ? "alert alert-danger mt-3 mb-0" : "alert alert-success mt-3 mb-0"}
                         role="alert">
                        {emailCodeMessage}
                    </div>
                    <button disabled={loading || sendEmailSuccess} className="w-100 btn btn-lg signin mt-3"
                            type="submit">Request code
                    </button>

                </form>

                <form onSubmit={onSubmit}>
                    <div className="form-floating mt-3">
                        <Input disabled={(loading) ? true : false}
                               type={"password"}
                               id={"field2"}
                               required={true}
                               value={password}
                               setValue={setPassword}
                               label={"New password"}
                               validation={PasswordFormRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={formPasswordValidateMessages(password).join(' ')}
                               placeholder={"New password"}
                               labelBottom={true}
                        />
                    </div>
                    <div className="form-floating confirm-password">
                        <Input disabled={(loading) ? true : false}
                               type={"password"}
                               id={"field3"}
                               required={true}
                               value={confirmPassword}
                               setValue={setConfirmPassword}
                               label={"Confirm new password"}
                               validation={GenericInputRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={GenericInputRegex.helpText}
                               placeholder={"Confirm new password"}
                               labelBottom={true}
                        />
                    </div>
                    <div className="form-floating code">
                        <Input disabled={(loading) ? true : false}
                               type={"text"}
                               id={"field4"}
                               required={true}
                               value={confirmationCode}
                               setValue={setConfirmationCode}
                               label={"Confirmation code from email"}
                               validation={GenericInputRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={GenericInputRegex.helpText}
                               placeholder={"Confirmation code from email"}
                               labelBottom={true}
                        />
                    </div>
                    <div hidden={!passwordCaptcha || !sendEmailSuccess} className="form-floating mt-3"
                         style={{color: "#FFF"}}>
                        Captcha:
                        <img src={passwordCaptcha?.image} style={{margin: 3, backgroundColor: "#CCC"}}/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             className="bi bi-arrow-clockwise" viewBox="0 0 16 16"
                             onClick={() => {
                                 loadPasswordCaptcha();
                             }}>
                            <path fill-rule="evenodd"
                                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                            <path
                                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                        </svg>
                    </div>
                    <div hidden={!sendEmailSuccess} className="form-floating mt-3">
                        <Input disabled={(loading) ? true : false}
                               type={"text"}
                               id={"PasswordCaptcha"}
                               required={true}
                               value={passwordCaptchaResponse}
                               setValue={setPasswordCaptchaResponse}
                               label={"Type characters in Captcha image above"}
                               validation={GenericInputRegex.expression}
                               doValidate={formInputValidate}
                               invalidFeedbackText={GenericInputRegex.helpText}
                               placeholder={"Type characters in Captcha image above"}
                               labelBottom={true}
                        />
                    </div>
                    <div hidden={!resetPasswordMessage}
                         className={(isResetPasswordError) ? "alert alert-danger mt-3 mb-0" : "alert alert-success mt-3 mb-0"}
                         role="alert">
                        {resetPasswordMessage}
                    </div>
                    <button className="w-100 btn btn-lg signin mt-3" type="submit">Reset password</button>
                </form>
                <p className="mt-5 mb-3 text-muted">&copy; 2015–2022</p>
            </main>
        </>
    );
}

export default ResetPassword;