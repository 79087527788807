import * as React from 'react';
import './container.css';
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import Profile from "../../Components/Profile";
import {useEffect} from "react";
import ActivationRequired from "../../Components/ActivationRequired";
import ConfirmEmail from "../../Components/ConfirmEmail";



const PrivateMainContainer: React.FunctionComponent = () => {
    let {path} = useRouteMatch();



    return (<>
        <Switch>
            <Route path={path + "/profile"}>
                <Profile/>
            </Route>
            <Route path={path + "/activation-required"}>
                <ActivationRequired/>
            </Route>

        </Switch>
    </>);
};

export default PrivateMainContainer;